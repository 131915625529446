export const sopEnglish = [
  {
    id: 1,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-01.jpg",
    title: "Camera Alignment",
  },
  {
    id: 2,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-02.jpg",
    title: "Obstructing the Pipe View in the Truck",
  },
  {
    id: 3,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-03.jpg",
  },
];

export const sopHindi = [
  {
    id: 1,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-01.jpg",
    title: "Camera Alignment",
  },
  {
    id: 2,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-02.jpg",
    title: "Obstructing the Pipe View in the Truck",
  },
  {
    id: 3,
    img: "https://alvision-pi.s3.ap-south-1.amazonaws.com/SOP_Pipe-03.jpg",
  },
];
