import React from "react";
import { Button, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { validationSchema } from "../schemas/TruckValidationSchema";
import "../styles/truck-details.scss";
import { Container } from "react-bootstrap";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import enterTruckNoAudio from "../assets/audio/enterTruckNo.mp3";
import formSubmitted from "../assets/audio/formSubmitted.mp3";
import formNotSubmitted from "../assets/audio/formNotSubmitted.mp3";
import axios from "axios";

function TruckDetailsForm() {
  const audio = new Audio(enterTruckNoAudio);
  const formSubmittedAudio = new Audio(formSubmitted);
  const formNotSubmittedAudio = new Audio(formNotSubmitted);

  // Function to play the audio
  const playAudio = () => {
    audio.play();
  };

  const formik = useFormik({
    initialValues: {
      truck_no: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let audioPlayed = false; // Initialize a variable to track whether audio has been played

      async function detectCircle() {
        const res = await axios(
          "https://bece95aae9b2.ngrok.app/detect_circles"
        );
        console.log("detect circle", res.data);
      }

      detectCircle().then(() => {
        setInterval(() => {
          async function flag() {
            const res = await axios.get("https://bece95aae9b2.ngrok.app/flag");
            console.log("flag", res.data);

            if (!audioPlayed) {
              if (res.data === "finishing operations") {
                formSubmittedAudio.play();
                async function insertData() {
                  const res = await axios.get(
                    "https://bece95aae9b2.ngrok.app/insert_data"
                  );
                  console.log("insert data", res);
                  audioPlayed = true; // Set the variable to true after playing audio
                }
                insertData();
              } else {
                formNotSubmittedAudio.play();
                audioPlayed = true; // Set the variable to true after playing audio
              }
            }
          }
          flag();
        }, 3000);
      });
    },
  });

  return (
    <Container className="truck-details-container">
      <h3>Enter truck number | ट्रक नंबर दर्ज करें</h3>
      <img
        src="https://bece95aae9b2.ngrok.app/video_feed"
        alt=""
        width="100%"
      />

      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            size="small"
            margin="dense"
            variant="filled"
            fullWidth
            id="truck_no"
            name="truck_no"
            label="ट्रक नंबर दर्ज करें"
            value={formik.values.truck_no}
            onChange={formik.handleChange}
            error={formik.touched.truck_no && Boolean(formik.errors.truck_no)}
            helperText={formik.touched.truck_no && formik.errors.truck_no}
            inputProps={{ maxLength: 10 }}
          />

          <IconButton style={{ marginLeft: "10px" }} onClick={playAudio}>
            <VolumeUpIcon />
          </IconButton>
        </div>

        <Button
          fullWidth
          type="submit"
          className="submit-form-btn"
          aria-label="login"
        >
          Submit | सबमिट करें
        </Button>
      </form>
    </Container>
  );
}

export default TruckDetailsForm;
