import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { IconButton } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import GroupsIcon from "@mui/icons-material/Groups";
import BarChartIcon from "@mui/icons-material/BarChart";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

function StampOverview(props) {
  return (
    <div className="chart column-chart">
      <h3>Welcome Kriti Industries Limited,</h3>
      <p>Welcome back to ALVision PI</p>
      <Row>
        <Col xs={6} style={{ padding: 0 }}>
          <div
            style={{
              border: "1px solid #000",
              margin: "5px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton>
                <div>
                  <VideoCameraBackIcon />
                </div>
              </IconButton>
              <div>1</div>
            </div>
            <div style={{ textAlign: "center" }}>Camera Count</div>
          </div>
        </Col>

        <Col xs={6} style={{ padding: 0 }}>
          <div
            style={{
              border: "1px solid #000",
              margin: "5px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton>
                <div>
                  <BarChartIcon />
                </div>
              </IconButton>
              <div>{props.totalPipes}</div>
            </div>
            <div style={{ textAlign: "center" }}>Pipe Count</div>
          </div>
        </Col>

        <Col xs={6} style={{ padding: 0 }}>
          <div
            style={{
              border: "1px solid #000",
              margin: "5px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton>
                <div>
                  <LocalShippingIcon />
                </div>
              </IconButton>
              <div>1</div>
            </div>
            <div style={{ textAlign: "center" }}>Vehicle Count</div>
          </div>
        </Col>

        <Col xs={6} style={{ padding: 0 }}></Col>
      </Row>
    </div>
  );
}

export default StampOverview;
