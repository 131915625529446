import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TableViewIcon from "@mui/icons-material/TableView";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const sidebarData = [
  { id: 1, icon: <HomeRoundedIcon />, name: "Dashboard", url: "dashboard" },
  {
    id: 2,
    icon: <AssessmentIcon />,
    name: "Reports",
    url: "pipe-reports",
  },
  {
    id: 3,
    icon: <TaskAltIcon />,
    name: "SOP",
    url: "sop",
  },
];
