import "./App.scss";
import { useState } from "react";
import { Container } from "react-bootstrap";
import NavbarComponent from "./components/NavbarComponent";
import { ThemeProvider } from "@mui/material/styles";
import useMuiTheme from "./customHooks/useMuiTheme";
import { UserContext } from "./contexts/UserContext";
import LoginPage from "./pages/LoginPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "isLoggedIn");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  const muiTheme = useMuiTheme();

  return (
    <UserContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, handleLogin, handleLogout }}
    >
      <ThemeProvider theme={muiTheme}>
        <div className="App">
          {/* {isLoggedIn ? (
            <LoginPage />
          ) : ( */}
          <Container fluid style={{ padding: 0 }}>
            <NavbarComponent />
          </Container>
          {/* )} */}
        </div>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
