import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../styles/sidebar.scss";
import { sidebarData } from "../assets/data/SidebarData";
import { IconButton, List, ListItem, ListItemButton } from "@mui/material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { sidebarReportsList } from "../assets/data/sidebarReportsList";
import { UserContext } from "../contexts/UserContext";

function Sidebar() {
  const { handleLogout } = useContext(UserContext);
  return (
    <div className="sidebar">
      <List>
        <div className="avatar">
          <img
            src={require("../assets/images/logo.jpg")}
            alt="logo"
            width={150}
            style={{ borderRadius: "100%" }}
          />
        </div>

        {sidebarData.map((val) => {
          const { id, icon, name, url } = val;

          return (
            <div key={id}>
              <ListItem disableGutters={true} className="sidebar-listItem">
                {name === "Reports" ? (
                  <NavLink to={`/${url}`} key={id} className="sidebar-link">
                    <ListItemButton
                      sx={{ textAlign: "left" }}
                      className="appbar-links"
                      style={{ padding: "5px 0" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0 10px",
                        }}
                      >
                        <IconButton sx={{ color: "#ffffff9f" }}>
                          {icon}
                        </IconButton>
                        <p className="sidebar-list-text">{name}</p>
                      </div>
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <NavLink to={`/${url}`} key={id} className="sidebar-link">
                    <ListItemButton
                      sx={{ textAlign: "left" }}
                      className="appbar-links"
                      style={{ padding: "5px 0" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton sx={{ color: "#ffffff9f" }}>
                          {icon}
                        </IconButton>
                        <p className="sidebar-list-text">{name}</p>
                      </div>
                    </ListItemButton>
                  </NavLink>
                )}
              </ListItem>

              {name === "Reports" &&
                sidebarReportsList.map((list) => {
                  return (
                    <ListItem
                      disableGutters={true}
                      key={list.id}
                      className="sidebar-listItem"
                      style={{ padding: "0 10px" }}
                    >
                      <NavLink
                        to={`/${list.url}`}
                        key={id}
                        className="sidebar-link"
                      >
                        <ListItemButton
                          sx={{ textAlign: "left" }}
                          className="appbar-links"
                          style={{ padding: "5px 0" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0 15px",
                            }}
                          >
                            <IconButton sx={{ color: "#ffffff9f" }}>
                              {icon}
                            </IconButton>
                            <p className="sidebar-list-text">{list.name}</p>
                          </div>
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  );
                })}
            </div>
          );
        })}

        <ListItem
          sx={{ textAlign: "left" }}
          className="sidebar-listItem"
          style={{
            padding: "5px 0",
          }}
        >
          <div className="sidebar-link">
            <ListItemButton
              sx={{ textAlign: "left" }}
              className="appbar-links"
              onClick={() => {
                handleLogout();
              }}
              style={{
                padding: "5px 0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton sx={{ color: "#ffffff9f" }}>
                  <LogoutRoundedIcon />
                </IconButton>
                <p className="sidebar-list-text">Logout</p>
              </div>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
    </div>
  );
}

export default Sidebar;
