import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";
import "../styles/dashboard.scss";
import StampOverview from "./Stamp/dashboardComponents/Overview";
import EnvironmentBenefits from "./Stamp/dashboardComponents/EnvironmentBenefits";
import { DataGrid } from "@mui/x-data-grid";
import { validationSchema } from "../schemas/TruckValidationSchema";
import { useFormik } from "formik";
import { TextField } from "@mui/material";

function Dashboard() {
  const [tables, setTables] = useState([]);

  useEffect(() => {
    async function getTables() {
      const res = await axios.get(
        "https://bece95aae9b2.ngrok.app/tables/?skip=0&limit=5"
      );
      setTables(res.data);
    }
    getTables();
  }, []);

  const totalPipes = tables.reduce(
    (a, b) => a + parseInt(b.Total_Pipes_per_Vehicle),
    0
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      sortable: false,
      align: "center",
    },
    {
      field: "Date",
      headerName: "Date",
      width: 100,
      sortable: false,
      align: "center",
    },
    {
      field: "Time",
      headerName: "Time",
      width: 100,
      sortable: false,
      align: "center",
    },
    {
      field: "Truck",
      headerName: "Truck",
      width: 100,
      renderCell: (params) => {
        const rowIndex = params.api.getRowIndex(params.id);
        const truckIndex = rowIndex + 1;
        return `Truck ${truckIndex}`;
      },
    },
    {
      field: "Total_Pipes_per_Vehicle",
      headerName: "Number of Pipes",
      width: 150,
      sortable: false,
      align: "center",
    },
  ];

  let codeExecuted = false;

  const formik = useFormik({
    initialValues: {
      truck_no: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const res = await axios.post("https://bece95aae9b2.ngrok.app/truck_no", {
        truck_number: values.truck_no,
      });
      console.log("Submit truck number", res.data);

      if (values.truck_no !== "") {
        async function handleSnapshot() {
          async function detectCircle() {
            window.alert("Detection Started");
            const res = await axios(
              "https://bece95aae9b2.ngrok.app/detect_circles"
            );
            console.log("detect circle", res.data);
          }
          detectCircle().then(() => {
            setInterval(() => {
              async function flag() {
                const res = await axios.get(
                  "https://bece95aae9b2.ngrok.app/flag"
                );
                console.log("flag", res.data);

                if (!codeExecuted && res.data === "finishing operations") {
                  async function insertData() {
                    const res = await axios.get(
                      "https://bece95aae9b2.ngrok.app/insert_data"
                    );
                    console.log("insert data", res);
                    codeExecuted = true;
                  }
                  insertData();
                }
              }
              flag();
            }, 3000);
          });
        }
        handleSnapshot();
      } else {
        alert("Please enter truck number");
      }
    },
  });

  const handleTruckNoChange = (event) => {
    const newValue = event.target.value.toUpperCase(); // Convert to uppercase
    formik.handleChange(event); // Handle Formik's onChange event
    formik.setFieldValue("truck_no", newValue); // Update the formik field value
  };

  return (
    <>
      <Container fluid className="dashboard">
        <Row className="dashboard-row">
          <Col xs={12} lg={5} className="dashboard-col">
            <StampOverview totalPipes={totalPipes} />
          </Col>
          <Col xs={12} lg={7} className="dashboard-col">
            <div className="chart column-chart">
              <img
                src="https://bece95aae9b2.ngrok.app/video_feed"
                alt=""
                width="100%"
              />

              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <TextField
                  size="small"
                  margin="dense"
                  variant="filled"
                  fullWidth
                  id="truck_no"
                  name="truck_no"
                  label="ट्रक नंबर दर्ज करें"
                  value={formik.values.truck_no}
                  onChange={handleTruckNoChange}
                  error={
                    formik.touched.truck_no && Boolean(formik.errors.truck_no)
                  }
                  helperText={formik.touched.truck_no && formik.errors.truck_no}
                  inputProps={{ maxLength: 10 }}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button type="submit" className="download-btn">
                    Take Snapshot
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="dashboard">
        <Row className="dashboard-row">
          <Col lg={12} xl={7} className="dashboard-col">
            <div className="chart-table">
              <DataGrid
                getRowId={(row) => row.id}
                sx={{
                  padding: "0 30px",
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#f8f5ff",
                  },
                }}
                className="dashboard-table"
                headerAlign="center"
                rows={tables}
                columns={columns}
                pageSize={50}
                stickyHeader
                rowsPerPageOptions={[50]}
                autoHeight={true}
                disableColumnMenu={true}
                disableSelectionOnClick
              />
            </div>
          </Col>
          <Col xs={12} lg={5} className="dashboard-col">
            <EnvironmentBenefits />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
