export const sidebarReportsList = [
  // {
  //   id: 1,
  //   name: "Ideal Time Report",
  //   url: "ideal-time-report",
  // },
  // {
  //   id: 2,
  //   name: "CCTVs",
  //   url: "cctv",
  // },
  // {
  //   id: 3,
  //   name: "Item-wise Production Data",
  //   url: "item-wise-production-data",
  // },
  // { id: 4, name: "Machine Details Data", url: "machine-details-data" },
  { id: 5, name: "Pipe Reports", url: "pipe-reports" },
  // { id: 6, name: "Machine-wise Utilisation", url: "machine-wise-utilisation" },
];
