import { DataGrid } from "@mui/x-data-grid";
import "../../../styles/throughput.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../assets/images/logo.jpg";
import img from "../../../assets/images/Pi.png";
import bg from "../../../assets/images/bg.png";

export default function PipeReport(props) {
  const [pageNumber, setPageNumber] = useState(0);
  const [totalData, setTotalData] = useState();
  const dataPerPage = 10;
  const pageCount = Math.ceil(totalData / dataPerPage);

  const handleNextPage = () => {
    if (pageNumber < pageCount - 1) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePreviousPage = () => {
    if (pageNumber >= 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const timestamp = new Date();
  const date = timestamp.toLocaleDateString();
  const time = timestamp.toLocaleTimeString();

  const [table, setTable] = useState([]);

  useEffect(() => {
    async function getData() {
      await axios
        .get(`https://bece95aae9b2.ngrok.app/data/${pageNumber}`)
        .then((res) => {
          setTable(res.data[0]);
          setTotalData(res.data[1]);
        });
    }

    getData();
  }, [pageNumber]);

  async function downloadReport() {
    const res = await axios.get(
      "https://bece95aae9b2.ngrok.app/download_report"
    );
    console.log("download report", res.data);
    if (res.data === "File Downloaded") {
      window.alert(res.data);
    }
    var byteCharacters = atob(res.data);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadReport");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `Report_${date}_${time}`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  }

  const downloadOriginal = (base64String, fileName) => {
    var byteCharacters = atob(base64String);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadOriginal");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `${fileName}-Original`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  };

  const downloadProcessed = (base64String, fileName) => {
    var byteCharacters = atob(base64String);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadProcessed");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `${fileName}-Processed`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  };

  const handleDownloadReport = () => {
    // Get the JSON data from the rows
    const jsonData = table.map((row) => ({
      ID: row.id,
      Date: row.Date,
      Time: row.Time,
      "Total Pipes per Vehicle": row.Total_Pipes_per_Vehicle,
    }));

    // Initialize the PDF instance
    const pdf = new jsPDF("p", "pt", "a4", true);

    // Add the background color up to 20% from the top
    const headerHeight = pdf.internal.pageSize.getHeight() * 0.2;
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), headerHeight, "F");

    // Add the header background image
    const headerBgImageData = bg;
    pdf.addImage(
      headerBgImageData,
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      headerHeight
    );

    const textY = headerHeight / 2;

    // Add the logo
    const headerImgData = logo;
    const headerImgWidth = 100;
    const headerImgHeight = 100;
    pdf.addImage(
      headerImgData,
      "PNG",
      40,
      textY - 10 - 25,
      headerImgWidth,
      headerImgHeight
    );

    // Calculate the x-coordinate for aligning the headings to the right
    const headingX = pdf.internal.pageSize.getWidth() - 40;

    const titleText = "Kriti Industries";
    const subtitleText = `Total Pipe Count Report on ${props.date
      .split("-")
      .reverse()
      .join("-")}`;
    // const machineText = "Machine 1";

    pdf.setFontSize(24);
    pdf.setTextColor("#ffffff");
    pdf.text(titleText, headingX, textY - 10, null, null, "right");
    pdf.setFontSize(14);
    pdf.text(subtitleText, headingX, textY + 10, null, null, "right");
    pdf.setFontSize(12);
    // pdf.text(machineText, headingX, textY + 30, null, null, "right");

    // Convert the JSON data to arrays of headers and rows
    const headers = ["ID", "Date", "Time", "Total Pipes per Vehicle"];
    const rowsData = jsonData.map((item, index) => {
      return [
        {
          content: item["ID"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Date"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Time"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Total Pipes per Vehicle"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
      ];
    });

    // Add the table using pdf.autoTable
    pdf.autoTable({
      startY: headerHeight + 20,
      head: [headers],
      body: rowsData,
      styles: {
        halign: "center",
        valign: "middle",
        fontSize: 12,
        cellPadding: 5,
      },
      headStyles: {
        fillColor: "#484848",
        textColor: "#fff",
        fontStyle: "bold",
      },
      columnStyles: {
        Timing: { fontStyle: "bold" },
        "Production Count": { fontStyle: "bold" },
      },
    });

    // Add another image at the footer
    const footerImgData = img;
    const footerImgWidth = 150; // Adjust the width of the footer image as needed
    const footerImgHeight = 20;
    const footerTextX = 40 + footerImgWidth + 10;

    // Set the line color to light gray (R, G, B values)
    pdf.setDrawColor(200, 200, 200);

    // Calculate the Y-coordinate for the line
    const lineY = pdf.internal.pageSize.getHeight() - footerImgHeight - 30; // Adjust the value to control the vertical position of the line

    // Draw the horizontal line
    pdf.line(40, lineY, pdf.internal.pageSize.getWidth() - 40, lineY);

    pdf.addImage(
      footerImgData,
      "PNG",
      40,
      pdf.internal.pageSize.getHeight() - footerImgHeight - 20,
      footerImgWidth,
      footerImgHeight
    );

    const footerTextY =
      pdf.internal.pageSize.getHeight() - footerImgHeight - 17; // Adjust the value to control the vertical position of the text
    const companyText = "Alluvium IOT Solutions Pvt. Ltd.";
    const addressText = "A-306, Wallstreet 2, Ellisbridge";
    const cityText = "Ahmedabad, Gujarat";
    const contactText = "+91 9924300511";

    pdf.setFontSize(8);
    pdf.setTextColor("#000000");
    pdf.text(companyText, footerTextX, footerTextY, null, null, "left");
    pdf.text(addressText, footerTextX, footerTextY + 10, null, null, "left");
    pdf.text(cityText, footerTextX, footerTextY + 20, null, null, "left");
    pdf.text(contactText, footerTextX, footerTextY + 30, null, null, "left");

    // Save the PDF file
    const filename = `${props.date}.pdf`;
    pdf.save(filename);
  };

  return (
    <>
      <div>
        <h4 style={{ textAlign: "center" }}>Kriti Industries limited</h4>
        <p style={{ textAlign: "center" }}>Daily Report</p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="download-btn"
            style={{ marginTop: 0, marginBottom: "40px" }}
            id="downloadReport"
            // onClick={() => downloadReport()}
            onClick={() => handleDownloadReport()}
          >
            Download Report
          </button>
        </div>
      </div>

      <div className="table-container">
        <table
          className="table table-bordered throughput-table"
          style={{ backgroundColor: "#fff" }}
        >
          <thead>
            <tr>
              <td>
                <strong>ID</strong>
              </td>
              <td>
                <strong>Date</strong>
              </td>
              <td>
                <strong>Time</strong>
              </td>
              <td>
                <strong>Original Image</strong>
              </td>
              <td>
                <strong>Processed Image</strong>
              </td>
              <td>
                <strong>Number of Pipes</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {table.map((data, id) => {
              return (
                <tr key={id}>
                  <td>{data.id}</td>
                  <td>{data.Date}</td>
                  <td>{data.Time}</td>
                  <td>
                    <a
                      id="downloadOriginal"
                      onClick={() =>
                        downloadOriginal(data.Original_Image, data.id)
                      }
                      className="download-image-link"
                    >
                      {data.id}
                    </a>
                  </td>
                  <td>
                    <a
                      id="downloadProcessed"
                      onClick={() =>
                        downloadProcessed(data.Process_Image, data.id)
                      }
                      className="download-image-link"
                    >
                      {data.id}
                    </a>
                  </td>
                  <td>{data.Total_Pipes_per_Vehicle}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        Page {pageNumber + 1} of {pageCount}
        <button
          className="download-btn"
          onClick={handlePreviousPage}
          disabled={pageNumber <= 0}
          style={{ marginLeft: "5px" }}
        >
          Previous
        </button>
        <button
          className="download-btn"
          style={{ marginLeft: "5px" }}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    </>
  );
}
